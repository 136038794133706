<template>
  <div>
    <ConfirmDialog
      style="white-space: break-spaces"
      class="w-auto md:w-6"
    ></ConfirmDialog>
    <BlockUI :blocked="blockedPage" :fullScreen="true"></BlockUI>
    <ProgressSpinner v-if="loadingPage" class="spinner" />
    <div class="grid pt-5">
      <div class="col-12" :class="checkRole(['client']) ? 'md:col-6' : ''">
        <Card>
          <template #title> {{ $t("profile.userInformation") }} </template>
          <template #content>
            <div class="grid grid-nogutter flex-column">
              <div class="col">
                <Skeleton
                  v-if="loading"
                  width="50%"
                  height="2rem"
                  class="mb-2"
                ></Skeleton>
                <h3 v-else>
                  <template v-if="userId">
                    {{
                      userData.company != null
                        ? userData.company.companyName
                        : $t("global.noValue")
                    }}
                  </template>
                  <template v-else-if="checkRole(['client'])">
                    {{
                      clientInfo ? clientInfo.companyName : $t("global.noValue")
                    }}
                  </template>
                  <template v-else>
                    {{
                      userData.companyName
                        ? userData.companyName
                        : $t("global.noValue")
                    }}
                  </template>
                </h3>
              </div>
              <div class="col">
                <Skeleton
                  v-if="loading"
                  width="25%"
                  height="1.2rem"
                  class="mb-2"
                ></Skeleton>
                <b v-else>
                  {{ userData.email }}
                </b>
              </div>
              <div class="col">
                <Skeleton
                  v-if="loading"
                  width="20%"
                  height="1.2rem"
                  class="mb-2"
                ></Skeleton>
                <b v-else>
                  <template v-if="userId">
                    {{ userData.firstName }} {{ userData.lastName }}
                  </template>
                  <template v-else>
                    {{ userData.name }}
                  </template>
                </b>
              </div>
              <div class="col">
                <Skeleton
                  v-if="loading"
                  width="20%"
                  height="1.2rem"
                  class="mb-2"
                ></Skeleton>
                <b v-else>
                  <template v-if="userId">
                    {{ userData.roles[0] ? userData.roles[0].name : "client" }}
                  </template>
                  <template v-else>
                    {{ getUserRoleTranslated }}
                  </template>
                </b>
              </div>
              <div class="col">
                <Skeleton
                  v-if="loading"
                  width="50%"
                  height="1.2rem"
                  class="mb-2"
                ></Skeleton>
                <b v-else>
                  <template v-if="userId">
                    <template
                      v-if="userData.company && 'address' in userData.company"
                    >
                      {{
                        userData.company.address.street
                          ? `${userData.company.address.street},`
                          : ""
                      }}
                      {{
                        userData.company.address.postalCode
                          ? `${userData.company.address.postalCode},`
                          : ""
                      }}, {{ userData.company.address.city }},
                      {{ userData.company.address.country }}
                    </template>
                    <template v-else>
                      {{ $t("global.noValue") }}
                    </template>
                  </template>
                  <template v-else-if="checkRole(['client'])">
                    <template v-if="clientInfo && 'address' in clientInfo">
                      {{
                        clientInfo.address.street
                          ? `${clientInfo.address.street},`
                          : ""
                      }}
                      {{
                        clientInfo.address.postalCode
                          ? `${clientInfo.address.postalCode},`
                          : ""
                      }}
                      {{ clientInfo.address.city }},
                      {{ clientInfo.address.country }}
                    </template>
                    <template v-else>
                      {{ $t("global.noValue") }}
                    </template>
                  </template>
                </b>
              </div>
              <div class="col">
                <Skeleton
                  v-if="loading"
                  width="30%"
                  height="1.2rem"
                  class="mb-2"
                ></Skeleton>
                <b v-else>
                  <template v-if="userId">
                    <template
                      v-if="userData.company && 'contact' in userData.company"
                    >
                      {{ userData.company.contact.telephone }}
                    </template>
                    <template v-else>
                      {{ $t("global.noValue") }}
                    </template>
                  </template>
                  <template v-else-if="checkRole(['client'])">
                    <template v-if="clientInfo && 'contact' in clientInfo">
                      {{ clientInfo.contact.telephone }}
                    </template>
                    <template v-else>
                      {{ $t("global.noValue") }}
                    </template>
                  </template>
                  <template v-else>
                    {{
                      userData.telephone
                        ? userData.telephone
                        : $t("global.noValue")
                    }}
                  </template>
                </b>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div class="col-12 md:col-6" v-if="checkRole(['client'])">
        <Card class="h-full">
          <template #title>
            {{ $t("profile.userSettings") }}
          </template>
          <template #content>
            <Accordion
              :activeIndex="!loading && checkDeletedAccount ? 1 : null"
            >
              <!-- <AccordionTab :header="$t('profile.platformLanguages')">
                <div class="field">
                  <label for="language"
                    >{{ $t("profile.selectLanguage") }}:
                  </label>
                  <Dropdown
                    id="language"
                    v-model="$i18n.locale"
                    :options="languages"
                    optionLabel="name"
                    optionValue="code"
                    class="ml-3"
                    @change="saveLanguage"
                  />
                </div>
              </AccordionTab> -->
              <AccordionTab
                v-if="checkRole(['client'])"
                :header="$t('profile.privacySettings')"
              >
                <Skeleton
                  v-if="loading"
                  width="70%"
                  height="3rem"
                  class="mb-2"
                ></Skeleton>
                <Button
                  v-else-if="!loading && !checkDeletedAccount"
                  class="p-button-text p-button-plain p-button-danger"
                  @click="deleteAccount"
                  :label="$t('profile.deleteMyAccount')"
                ></Button>
                <Button
                  v-else-if="!loading && checkDeletedAccount"
                  class="p-button-text p-button-plain p-button-danger"
                  @click="downloadData"
                  :label="$t('profile.downloadZip')"
                ></Button>
              </AccordionTab>
            </Accordion>
          </template>
        </Card>
      </div>
    </div>

    <Card class="mt-5">
      <template #title>
        <div class="flex justify-content-between">
          <h4>
            {{ $t("profile.userTimeline") }}
          </h4>
          <Calendar
            :placeholder="$t('profile.fromTo')"
            v-model="dates"
            selectionMode="range"
            :maxDate="new Date()"
            dateFormat="dd/mm/yy"
            @hide="filterActions"
          />
        </div>
      </template>
      <template #content>
        <TimelineView :loading="loadingActions"></TimelineView>
      </template>
    </Card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TimelineView from "./Timeline.vue";

export default {
  name: "Profile",
  components: {
    TimelineView,
  },
  data() {
    return {
      loading: true,
      dates: [],
      userId: null,
      languages: [
        { name: this.$t("global.english"), code: "en" },
        { name: this.$t("global.greek"), code: "el" },
      ],
      blockedPage: false,
      loadingPage: false,
      loadingActions: false,
    };
  },
  methods: {
    async filterActions() {
      let dateStart = "";
      let dateEnd = "";
      if (this.dates != null) {
        dateStart = this.$dayjs(this.dates[0]).format("DD/MM/YYYY");
        dateEnd = this.dates[1]
          ? this.$dayjs(this.dates[1]).format("DD/MM/YYYY")
          : dateStart;
      }

      this.loadingActions = true;
      await this.$store.dispatch("getActionsGrouped", {
        userId: this.userId ? this.userId : this.userInfo.sub,
        dateStart,
        dateEnd,
      });
      this.loadingActions = false;
    },
    saveLanguage() {
      let data = { id: this.userInfo.sub, language: this.$i18n.locale };
      this.$store.dispatch("updateLanguage", data);
    },
    deleteAccount() {
      this.$confirm.require({
        message: `${this.$t("profile.deleteAccountContent")} \n \n ${this.$t(
          "profile.receiveEmail"
        )} \n \n ${this.$t("profile.thankYou")} \n \n ${this.$t(
          "profile.hopeAgain"
        )}`,
        header: this.$t("profile.accountDeletionHeader"),
        icon: "pi pi-exclamation-triangle",
        acceptLabel: this.$t("profile.deleteMe"),
        rejectLabel: this.$t("global.cancel"),
        acceptClass: "p-button-success",
        rejectClass: "p-button-danger",
        accept: async () => {
          // this.$toast.add({severity:'info', summary:'Confirmed', detail:'You have accepted', life: 3000});
          this.blockedPage = true;
          this.loadingPage = true;

          this.$action(
            "delete-account-request",
            { userId: this.userInfo.sub },
            "user"
          );
          await this.$store.dispatch("deleteAccount", this.userInfo.sub);
          await this.$store.dispatch("checkDeletedAccount", this.userInfo.sub);
          this.blockedPage = false;
          this.loadingPage = false;
        },
        reject: () => {
          // this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
        },
      });
    },
    checkUserId() {
      this.userId = null;
      if ("userId" in this.$route.query && this.$route.query.userId != "") {
        this.userId = this.$route.query.userId;
      }
    },
    async downloadData() {
      this.blockedPage = true;
      this.loadingPage = true;

      await this.$store.dispatch(
        "downloadDeletedAccountFile",
        this.userInfo.sub
      );

      this.blockedPage = false;
      this.loadingPage = false;
    },
  },
  watch: {
    "$route.query.userId"() {
      this.checkUserId();
    },
    "$i18n.locale"() {
      this.$dayjs.locale(this.$i18n.locale);
    },
  },
  computed: {
    ...mapGetters([
      "userInfo",
      "clientInfo",
      "getUserRoleTranslated",
      "userProfileById",
      "checkRole",
      "userDataId",
      "checkDeletedAccount",
    ]),
    userData() {
      if (this.userId) {
        console.log(this.userProfileById);
        return this.userProfileById;
      } else {
        return this.userInfo;
      }
    },
  },
  async mounted() {
    try {
      this.loading = true;
      this.checkUserId();
      this.$action("profile-page-view", {
        CompanyName:
          this.checkRole(["client"]) && this.clientInfo
            ? this.clientInfo.companyName
            : "",
        targetUsername: this.userId ? this.userDataId.username : "",
      });
      this.loadingActions = true;
      await this.$store
        .dispatch("getActionsGrouped", {
          userId: this.userId ? this.userId : this.userInfo.sub,
          dateStart: "",
          dateEnd: "",
        })
        .then(() => {
          this.loadingActions = false;
        });
      if (this.userId) {
        await this.$store.dispatch("userProfileById", this.userId);
        await this.$store.dispatch("userDataById", this.userId);
      } else {
        await this.$store.dispatch("userInfo");
      }

      if (this.checkRole(["client"])) {
        await this.$store.dispatch("checkDeletedAccount", this.userInfo.sub);
      }

      this.loading = false;
    } catch (err) {
      console.log(err);
      this.loading = false;
    }
    console.log(this.clientInfo);
  },
};
</script>
